import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { AlbumCard } from "../AlbumCard/AlbumCard.component"

import "./Music.styles.scss"

export const Music = () => {
  const music = useStaticQuery(graphql`
    query MusicQuery {
      allMarkdownRemark(sort: { fields: frontmatter___order }) {
        nodes {
          frontmatter {
            name
            subtitle
            title
            cover {
              childImageSharp {
                gatsbyImageData
              }
            }
            order
          }
          rawMarkdownBody
        }
      }
    }
  `)

  return (
    <section id="music" className="Music">
      <h2 className="Music__Header">Music</h2>
      <ul className="Music__List">
        {music.allMarkdownRemark.nodes.map(node => (
          <li className="Music__Item" key={node.frontmatter.name}>
            <AlbumCard {...node.frontmatter} player={node.rawMarkdownBody} />
          </li>
        ))}
      </ul>
    </section>
  )
}
