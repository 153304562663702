import React from "react"
import "./SweeterTab.styles.scss"

export const SweeterTab = React.forwardRef(function SweeterTab(props, ref) {
  return (
    <div ref={ref} className="SweeterTab">
      <iframe
        style={{ border: 0, width: "280px", height: "42px" }}
        src="https://bandcamp.com/EmbeddedPlayer/album=2556009668/size=small/bgcol=333333/linkcol=0f91ff/artwork=none/transparent=true/"
        seamless
      >
        <a href="https://victorbeach.bandcamp.com/album/sweeter-than-honey-l-a-version">
          Sweeter Than Honey (L.A. Version) by Victor Beach
        </a>
      </iframe>
    </div>
  )
})
