import React from "react"

import "./SpecialThanks.styles.scss"

export const SpecialThanks = () => {
  return (
    <section className="SpecialThanks">
      <h2>Vic would like to thank...</h2>
      <ul className="SpecialThanks__List">
        <li>Kenny Dooley</li>
        <li>John Thomas</li>
        <li>Paul Marshal Markowitz</li>
        <li>Mike Flynn</li>
        <li>Dave Martin</li>
        <li>Rusty Nix</li>
        <li>Dave Dorris</li>
        <li>Mark Banning</li>
        <li>Amanda Miller</li>
        <li>Nancy Shanks</li>
        <li>Dennis Bellfield</li>
        <li>J E Goodenough</li>
        <li>Stephen Welbourn</li>
        <li>Alexander Capezuto-Haraka</li>
      </ul>
    </section>
  )
}
