import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import "./AlbumCard.styles.scss"

export const AlbumCard = ({ name, cover, player }) => {
  const image = getImage(cover)
  return (
    <article className="AlbumCard">
      <figure className="AlbumCard__Image">
        <GatsbyImage
          image={image}
          width={500}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt={`${name} cover`}
        />
      </figure>

      <div className="AlbumCard__Content">
        <header className="AlbumCard__Description">
          <h3 className="AlbumCard__Header">{name}</h3>
        </header>

        <div
          className="AlbumContent__Audio"
          dangerouslySetInnerHTML={{ __html: player }}
        />
      </div>
    </article>
  )
}
