import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import BackgroundImage from "gatsby-background-image"

import "./Bio.styles.scss"

export const Bio = ({ children }) => {
  const data = useStaticQuery(graphql`
    query BackgroundQuery {
      imageSharp {
        id
      }
      file(relativePath: { eq: "musselman-nashville.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const bgImageData = data.file.childImageSharp.fluid

  return (
    <section id="about" className="Bio">
      <div className="Bio__parallax--back">
        <BackgroundImage
          Tag="div"
          className="Bio__BackgroundImage"
          fluid={bgImageData}
          style={{
            width: "100%",
            minHeight: "100vh",
            height: "100%",
          }}
          backgroundColor={`#040e18`}
        />
      </div>
      <div className="Bio__content">
        <p>Play the drums, that&apos;s what I do,</p>
        <p>Write some songs and sing em too.</p>
        <p>Bringing together only the best,</p>
        <p>LA, Nashville, all the rest</p>
      </div>
      {children}
    </section>
  )
}
