import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Bio } from "../components/Bio/Bio.component"
// import { Socials } from "../components/Socials/Socials.component"
import { SpecialThanks } from "../components/SpecialThanks/SpecialThanks.component"
import { Music } from "../components/Music/Music.component"
import { Live } from "../components/Live/Live.component"
import { SweeterTab } from "../components/SweeterTab/SweeterTab.component"

const IndexPage = () => {
  const sweeterRef = React.useRef(null)
  const headerRef = React.useRef(null)

  React.useEffect(() => {
    const musicObserver = new IntersectionObserver(entries => {
      const [entry] = entries
      if (!sweeterRef.current) return

      sweeterRef.current.classList.toggle("fixed", entry.isIntersecting)
    })

    if (headerRef.current) musicObserver.observe(headerRef.current)

    return () => {
      if (headerRef.current) musicObserver.unobserve(headerRef.current)
    }
  }, [])

  return (
    <Layout ref={headerRef}>
      <Seo title="Home" />
      <Bio>
        <SweeterTab ref={sweeterRef} />
      </Bio>
      {/* {<Socials />} */}
      <Music />
      <Live />
      <SpecialThanks />
    </Layout>
  )
}

export default IndexPage
