import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import "./Live.styles.scss"

export const Live = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      imageSharp {
        id
      }
      file(relativePath: { eq: "douglas.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const image = getImage(data.file)

  return (
    <section id="live" className="Media">
      <h2 className="Media__Header">Live</h2>
      <article className="Media__Item">
        <figure className="Media__photo-container">
          <GatsbyImage
            image={image}
            alt="The Victor Beach Experience playing at Douglas Corner Cafe"
            className="Media__Photo"
          />
        </figure>
        <iframe
          style={{ border: 0, maxWidth: "39rem", height: "42px" }}
          src="https://bandcamp.com/EmbeddedPlayer/track=1301912113/size=small/bgcol=333333/linkcol=ffffff/artwork=none/transparent=true/"
          seamless
        >
          <a href="https://victorbeach.bandcamp.com/track/handle-with-care-live">
            Handle With Care (Live) by Victor Beach
          </a>
        </iframe>
        <figcaption className="Media__Caption">
          <h3>The Victor Beach Experience</h3>{" "}
          <p>Live at the legendary Douglas Corner Cafe in 2019</p>
        </figcaption>
      </article>
    </section>
  )
}
